<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-template')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @editBtnClick="handleEditData"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <el-button
          v-if="isSwitch && isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-lock"
          size="mini"
          plain
          type="warning"
          @click="handleDisableClick(scope.row)"
          >{{ $t('general.disable') }}</el-button
        >
        <el-button
          v-if="isSwitch && !isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-unlock"
          size="mini"
          plain
          @click="handleEnableClick(scope.row)"
          >{{ $t('general.enable') }}</el-button
        >
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/search'
import { contentTableConfig } from './config/table'

import { useRouter } from 'vue-router'
import { usePermission } from '@/hooks/use-permission'

// 表格基础配置项
const tableOptions = {
  moduleName: 'parameter', // 所属的模块
  pageName: 'template', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: true, // 是否显示添加按钮的插槽
  pageAction: 'param/getTemplateList', // 获取表格的Action
  deleteRecordAction: 'param/deleteTemplateById', // 删除record的Action
  pageCountGetter: 'param/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'param/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)

const pageContentRef = ref('')
const pageSearchRef = ref('')

// 获取页面操作的权限
const isSwitch = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'switch'
)

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()
const handleViewData = (id) => {
  router.push(`/template/overview/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/template/overview/edit/${id}`)
}

const handleNewData = () => {
  router.push(`/template/overview/new`)
}
const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}

const isNormalStatus = (status) => {
  return status === '00'
}
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit
const handleDisableClick = async (row) => {
  handleMessageCommit(
    'param/handleTerminalParamTemplateDisableAction',
    row.id
  ).then(() => {
    pageContentRef.value.getPageData()
  })
}
const handleEnableClick = async (row) => {
  handleMessageCommit(
    'param/handleTerminalParamTemplateEnableAction',
    row.id
  ).then(() => {
    pageContentRef.value.getPageData()
  })
}
</script>
