import { getCommonParamsItemLabel } from '@/utils/common'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'name',
      label: 'param.template-name'
    },
    {
      prop: 'templateType',
      label: 'param.template-type',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'template_type')
      }
    },
    // {
    //   prop: 'parameter',
    //   label: 'param.parameter'
    // },
    // {
    //   prop: 'md5',
    //   label: 'param.md5'
    // },
    // {
    //   prop: 'sha256',
    //   label: 'param.sha256'
    // },
    {
      prop: 'description',
      label: 'general.description'
    },
    {
      prop: 'status',
      label: 'common.app-status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      label: 'common.app-operation',
      width: '400',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
